import React, { useState, useEffect } from 'react'
import { LibraryQueCreate, LibraryQueDelete, LibraryQueDetail, LibraryQueList, LibraryQueTitleUiqueCheck, LibraryQueUpdate, validateFormData } from '../../Api/LibraryQue';
import { masterData } from '../../Api/Master';
import { defaultPage } from '../../Common/Constant';
import Footer from '../Layout/Footer'
import Header from '../Layout/Header'
import Pagination from '../Layout/Pagination';
import Sidebar from '../Layout/Sidebar'

function Question() {
    useEffect(() => {
        document.title = "Question - Mindforce"
    },);
    const [filters, setFilters] = useState([])
    const [searchKeyword, setSearchKeyword] = useState();
    const [lists, setLists] = useState();
    const [pageInfo, setPageInfo] = useState(defaultPage)
    const [showOption, setShowOption] = useState(false)
    const [countries, setCountries] = useState();
    const [languages, setLanguages] = useState();
    const [controlType, setControlType] = useState()
    const [submitBtnTxt, setSubmitBtnTxt] = useState('Submit')
    const [queId, setQueId] = useState()
    const [countryId, setCountryId] = useState()
    const [languageId, setLanguageId] = useState()
    const [controlTypeId, setControlTypeId] = useState()
    const [title, setTitle] = useState();
    const [editFormTitle, setEditFormTitle] = useState()
    const [question, setQuestion] = useState()
    const [addOption, setAddOption] = useState()
    const [mappedOption, setMappedOption] = useState([])
    const [errors, setErrors] = useState({});

    useEffect(() => {
        getList();
    },[countryId, languageId])

    useEffect(() => {
        getCountries()
        // getLanguages()
        getControlType()
    },[])

    useEffect(() => {
        getLanguages()
    },[])

    const getCountries = async() => {
        let res = await masterData({moduleName:'country'})
        if(res.status === 1){
            setCountries(res.payload)
        }
    }

    const getLanguages = async() => {
        let res = await masterData({moduleName:'language'})
        if(res.status === 1){
            setLanguages(res.payload)
        }
    }

    const getControlType = async() => {
        let res = await masterData({moduleName:'control-type'})
        if(res?.status === 1){
            setControlType(res.payload)
        }
    }

    const getList = async() =>{
        if(languageId){
            let res = await LibraryQueList({page:0, searchKeyword:searchKeyword, filters:filters, countryID: countryId, languageID: languageId});
            if(res.status === 1){
                setLists(res.payload)
                setPageInfo(res.paginationInfo)
            }else{
                setLists([])
            }
        }
        
    }

    const handleControlType = (e) => {
        let value = e.target.value
        // console.log('value',e)
        setShowOption(true)
        if(value === '' || value === null || value === '433' || value === 433){
            setShowOption(false)
        }
        setControlTypeId(value)
        // if (!!errors['controlTypeID'])
        //     setErrors({...errors, ['controlTypeID']: null})
    }

    const checkUnique = async () => {
        setErrors({...errors, ['title']: null})
        if(languageId && title && title !== editFormTitle){
            let data = {
                languageID: parseInt(languageId),
                title: title
            }
            let res = await LibraryQueTitleUiqueCheck({payload:data})
            if(res.status === 0){
                setErrors({...errors, ['title']: res.message})
            }
        }
    }

    const handleAddOption = (e) => {
        setAddOption(e.target.value)
        // setForm({...form, ['description']:e.target.value})
    }

    const moveToMappedOption = async () => {
        addOption?.split('\n').map(function(item, i) {
            if(item !== '' || item !== null){
                if(mappedOption?.indexOf(item) === -1){
                    setMappedOption((mappedOption) => [...mappedOption, item])
                }
            }
            
        })
        await setAddOption("")
    }

    const removeMapped = (item) => {
        let mappedList = mappedOption.filter(maped => maped != item);
        setMappedOption(mappedList)
    }

    const handlesubmit = async (e) => {
        // console.log("sdfa");
        // e.preventDefault();
        setErrors()
        let formData = {
            mappedOptions:mappedOption,
            languageID:languageId,
            controlTypeID:controlTypeId,
            title:title,
            question:question,
            description:null
        };
        if(countryId)
            formData.countryID = countryId

        // console.log(formData)
        const formErrors = validateFormData(formData)
        if(Object.keys(formErrors).length > 0){
            setErrors(formErrors)
        }else{
            let res = await LibraryQueCreate({payload:formData});
            if(res.status === 1){
                setControlTypeId()
                setTitle()
                document.getElementById('title').value=""
                setQuestion("")
                document.getElementById('question').value=""
                setAddOption()
                setMappedOption([])
                getList();
            }else{
                setErrors({...errors,['submit']:res.message})
            }
        }
    }
    const handleQueTitle = async (e) => {
        if(!/[0-9a-zA-Z_]/.test(e.key)){
            e.preventDefault();
        }
    }

    const handleUpdate = async (e) => {
        // e.preventDefault();
        setErrors()
        let formData = {
            mappedOptions:mappedOption,
            countryID:countryId,
            languageID:languageId,
            controlTypeID:controlTypeId,
            title:title,
            question:question,
            description:null
        };
        const formErrors = validateFormData(formData)
        if(Object.keys(formErrors).length > 0){
            setErrors(formErrors)
        }else{
            let res = await LibraryQueUpdate({id:queId, payload:formData});
            if(res.status === 1){
                setQueId()
                setControlTypeId()
                setTitle()
                document.getElementById('title').value=""
                setQuestion("")
                document.getElementById('question').value=""
                setAddOption()
                setMappedOption([])
                setSubmitBtnTxt('Submit')
                getList();
            }else{
                setErrors({...errors,['submit']:res.message})
            }
        }
    }

    const editForm = async(queId) => {
        let res = await LibraryQueDetail({payload:queId})
        if(res.status === 1){
            let data = res.payload[0]
            setQueId(data.ID)
            setCountryId(data.countryID)
            setLanguageId(data.languageID)
            setControlTypeId(data.controlTypeID)
            // handleControlType(data.controlTypeID)
            setTitle(data.title)
            setEditFormTitle(data.title)
            setQuestion(data.question)
            setMappedOption(data.mappedOptions)
            setSubmitBtnTxt('Update')
            
            let controlTypeID = data?.controlTypeID
            setShowOption(true)
            if(controlTypeID === '' || controlTypeID === null || controlTypeID === '433' || controlTypeID === 433){
                setShowOption(false)
            }
            setControlTypeId(controlTypeID)
            }
    }

    const deleteRow = async (id) => {
        let res = await LibraryQueDelete({id:id})
        if(res.status === 1){
            getList()
        }
    }

    return (
        <div>
            {/* <Sidebar /> */}
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <div>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a >Library</a></li>
                                                <li className="breadcrumb-item active">Question</li>
                                            </ol>
                                        </div>
                                        <h4 className="mb-sm-0">Question</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                            <div className="row">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">
                                        {/* <!-- end card header --> */}
                                        <div className="card-body">
                                            <div className="">
                                                <form >
                                                    <div className="row">
                                                        {/* <!--end col--> */}
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="ForminputState" className="form-label mb-0"> Language <span className="required_field">*{errors?.languageID}</span></label>
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" 
                                                                    onChange={(e) => setLanguageId(e.target.value)}>
                                                                    <option value={""} disabled selected>Select Language</option>
                                                                    {languages?.map((row) => {
                                                                        return (<option value={row.id} selected={row.id === languageId ? true : false}>{row.name}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="ForminputState" className="form-label mb-0"> Country 
                                                                    {/* <span className="required_field">*{errors?.countryID}</span> */}
                                                                </label>
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" onChange={(e)=> setCountryId(e.target.value)}>
                                                                    <option value={""} disabled selected>Select Country</option>
                                                                    {countries?.map((row) => {
                                                                        return (<option value={row.id} selected={row.id === countryId ? true : false}>{row.name}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="ForminputState" className="form-label mb-0">Control Type <span className="required_field">*{errors?.controlTypeID}</span></label>
                                                                <select className="form-select"
                                                                disabled={languageId ? false:true}
                                                                onChange={(e) => handleControlType(e)}
                                                                >
                                                                    <option value={""}>Select Control</option>
                                                                    {controlType?.map((row) => {
                                                                        return (<><option value={row.id} selected={row.id === controlTypeId ? true : false}>{row.name}</option></>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {/* <!--end col--> */}
                                                        <div className="col-md-6">
                                                            <div className="mb-3">
                                                                <label htmlFor="title" className="form-label mb-0">Title<span className="required_field">*{errors?.title}</span></label>
                                                                <input 
                                                                    type="text" 
                                                                   className="form-control" 
                                                                    placeholder="Enter title" 
                                                                    id="title" 
                                                                    disabled={languageId ? false:true} 
                                                                    value={title}
                                                                    onKeyDown={(e) => handleQueTitle(e)}
                                                                    onChange={(e) => setTitle(e.target.value)} 
                                                                    onBlur={() => checkUnique()} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="mb-3">
                                                                <label htmlFor="question" className="form-label mb-0">Question <span className="required_field">*{errors?.question}</span></label>
                                                                <input 
                                                                    type="text" 
                                                                   className="form-control" 
                                                                    placeholder="Enter Question" 
                                                                    id="question" 
                                                                    value={question}
                                                                    disabled={languageId ? false:true} 
                                                                    onChange={(e) => setQuestion(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        
                                                        {showOption ? (<>
                                                            <div className="form_divide"><hr ></hr></div>
                                                            <div className="col-md-6">
                                                                <div className="mb-3">
                                                                    <label htmlFor="addOption" className="form-label mb-0">Add Option <span className="required_field">*{errors?.description}</span></label>
                                                                    <textarea className="form-control" id="addOption" rows="3" onChange={(e) => handleAddOption(e)} value={addOption}></textarea>
                                                                    <span className="max_char">Maximum limit of characters 250</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="mb-3">
                                                                    <label className="form-label mb-0">Mapped Option <span className="required_field">*{errors?.mappedOptions}</span></label>
                                                                    
                                                                    <div style={{height:"80px", overflowY:"scroll", border:"1px solid", padding: "0.5rem 0.9rem", borderColor:"#ced4da", borderRadius:"4px"}}>
                                                                    {mappedOption?.map((item) => {
                                                                        return (<><div style={{cursor:"pointer"}} onDoubleClick={() => removeMapped(item)}>{item}</div></>)
                                                                    })}
                                                                    </div>
                                                                    <span className="max_char">Maximum limit of characters 250</span>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div className="text-center mb-3">
                                                                    <span className="btn add_qustion " style={{backgroundColor:"gray",color:"white", cursor:"pointer"}} onClick={() => moveToMappedOption()}>Add</span>
                                                                </div>
                                                            </div>
                                                        </>) : (<>
                                                            
                                                        </>)}
                                                        
                                                        
                                                        
                                                        <div className="col-lg-12">
                                                            <div className="text-end">
                                                                {submitBtnTxt === 'Update' ? (
                                                                    <span style={{cursor:"pointer"}}className="btn btn-primary mx-2" onClick={() => handleUpdate()}>{submitBtnTxt}</span>
                                                                ) : (
                                                                    <span style={{cursor:"pointer"}}className="btn btn-primary mx-2" onClick={() => handlesubmit()}>{submitBtnTxt}</span>
                                                                )}
                                                                
                                                                <button type="submit" className="btn btn-secondary">Cancel</button>
                                                            </div>
                                                        </div>
                                                        {/* <!--end col--> */}
                                                    </div>
                                                    {/* <!--end row--> */}
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end col --> */}
                            </div>
                            <div className="row mt-2">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">

                                        {/* <!-- end card header --> */}

                                        <div className="card-body" style={{padding: "10px"}}>
                            <table className="table table-striped table-hover table_client">
                                <thead>
                                    <tr className="bg-primary-1" style={{color:"white"}}>
                                        <th scope="col">Sr. No.</th>
                                        <th>Action</th>
                                        <th scope="col" style={{textAlign:"left"}}>Question Title</th>
                                        <th scope="col" style={{textAlign:"left"}}>Description</th>
                                        {/* <th scope="col">Country</th>
                                        <th scope="col">Language</th>
                                        <th scope="col">Control Type</th> */}
                                    </tr>
                                </thead>
                                
                                <tbody>
                                {lists ? (<>
                                    {lists?.length > 0 ? (<>
                                        {lists?.map((row, i) => {
                                            return(<>
                                            <tr>
                                                <th scope="row">{i+1}</th>
                                                <td>
                                                    <span className="p-1"><i className="ri-edit-2-line pointerC" onClick={() => editForm(row?.ID)}></i></span>
                                                    <span className="p-1"><i className="ri-delete-bin-2-line del pointerC" onClick={() => deleteRow(row?.ID)}></i></span>
                                                </td>
                                                <td style={{textAlign:"left"}}><a onClick={() => editForm(row?.ID)}><span className="clickableText" style={{textAlign:"left"}}>{row?.title}</span></a></td>
                                                <td style={{textAlign:"left"}}>{row?.question}</td>
                                                {/* <td>{row?.countryName}</td>
                                                <td>{row?.languageName}</td>
                                                <td>{row?.controlTypeName}</td> */}
                                            </tr>
                                            </>)
                                        })}
                                    </>) : (<>
                                        <tr>
                                            <td colSpan={6}>No data found</td>
                                        </tr>
                                    </>)}
                                </>) : (<>
                                
                                </>)}
                                    
                                </tbody>
                            </table>
                            <Pagination />
                            </div>
                    </div>
                </div>
            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Question