import React, { useEffect, useState } from "react";
import { CCollapse, CButton, CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter } from '@coreui/react'
import { postAPI } from "../../../ApiService";

function PreScreenViewTempQuestion(props){
    const [questionDetail, setQuestionDetail] = useState(); 
    const [viewTempOption, setViewTempOption] = useState()
    const [preScreenerQuestionID, setPreScreenerQuestionID] = useState()
    const [tempQueList, setTempQueList] = useState()

    useEffect(() => {
        getTempQuestion()
    },[])
    
    const getTempQuestion = async () => {
        let data = {
            page: 0,
            recPerPage: 25,
            q: "",
            projectDetailID: props?.detail?.projectDetailID,
        }
        let res = await postAPI('/project/prescreen/get-temp-question-list', data)
        // let res = await TempQueList({ projectDetailID: props?.detail?.projectDetailID })
        if (res.status === 1) {
            setTempQueList(res.payload)
        }
    }

    const viewTempQueOpt = async(queId) => {
        if(viewTempOption === true){
            setViewTempOption(false)
        }else{
            setPreScreenerQuestionID(queId)
            let payload = {
                preScreenerQuestionID: queId,
                projectDetailID: props?.detail?.projectDetailID,
            }
            let res = await postAPI('/project/prescreen/get-temp-question-detail/',payload)
            // console.log('res', res)
            if(res?.status === 1){
                let mapOpts = [];
                setQuestionDetail(res?.payload?.[0])
                setViewTempOption(true)
            }
        }   
    }

    const linkTempQue = async(queId) => {
        props?.visible()
        props?.action(queId, 'linkQuestion')
        // let payload = {
        //     preScreenerQuestionID: queId,
        //     projectDetailID: props?.detail?.projectDetailID,
        // }
        // let res = await TempQueDetail({payload:payload})
        // if(res.status === 1){
        //     seteditQusetion(true)
        //     // setTempQuestionDetail(res?.payload?.[0])
        //     setPreScreenerQuestionID(queId)
        //     setSource('tempQue')
        //     setQuestionDetail(res?.payload?.[0])
        //     setVisibleViewTempQueModal(false)
        // }
    }

    const editTempQuestion = async(queId) => {
        props?.visible()
        props?.action(queId, 'editTempQuestion')
    }
    return (<>
    
                <CModalHeader>
                    <CModalTitle>Temp Question</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <div className="row g-3">
                        <div className='table-responsive'>
                            <table className="table table-striped table-hover table_client mt-3">
                                <thead>
                                    <tr className="bg-primary" style={{ color: "white" }}>
                                        <th scope="col">Id</th>
                                        <th scope="col" style={{textAlign:"left"}}>Title</th>
                                        <th scope="col" style={{textAlign:"left"}}>Question</th>

                                        <th scope="col" style={{textAlign:"left"}}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tempQueList?.map((q, i) => {
                                        return (<>
                                            <tr>
                                                <td>{i + 1}</td>
                                                <td style={{textAlign:"left"}}>{q?.title}</td>
                                                <td style={{textAlign:"left"}}>{q?.question}</td>

                                                <td style={{textAlign:"left"}}>
                                                    {/* <span className="p-1"><i className=" ri-drag-move-2-line pointerC" ></i></span> */}
                                                    <span className="p-1"><i className="ri-link  pointerC" title="Link" onClick={() => linkTempQue(q?.ID)}></i></span>
                                                    <span className="p-1"><i className="ri-eye-fill  pointerC" title="view" onClick={()=>viewTempQueOpt(q?.ID)}></i></span>
                                                    <span className="p-1"><i className="ri-edit-2-line pointerC" title="Edit" onClick={()=>editTempQuestion(q?.ID)}></i></span>
                                                    {/* <span className="p-1"><i className="ri-delete-bin-2-line del pointerC" onClick={() => deleteTempQue(q?.ID)}></i></span> */}
                                                </td>
                                            </tr>
                                        </>)
                                    })}

                                </tbody>
                            </table>
                        </div>
                    </div>

                    {viewTempOption ? (<>
                        <div className="row g-3">
                        <div className='table-responsive'>
                            <table className="table table-striped table-hover table_client mt-3">
                                <thead>
                                    <tr className="bg-primary" style={{ color: "white" }}>
                                        <th scope="col">Id</th>
                                        <th scope="col" style={{textAlign:"left"}}>Option</th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    {questionDetail?.mappedOptions?.map((o, i) => {
                                        return (<>
                                            <tr>
                                                <td>{i + 1}</td>
                                                <td style={{textAlign:"left"}}>{o?.option}</td>
                                            </tr>
                                        </>)
                                    })}

                                </tbody>
                            </table>
                        </div>
                    </div>
                    </>) : (<></>)}
                </CModalBody>
            
    </>)
}
export default PreScreenViewTempQuestion