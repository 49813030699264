import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";

import Footer from '../Layout/Footer'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import Multiselect from 'multiselect-react-dropdown';
import { masterData } from '../../Api/Master';
import { SupplierCreate } from '../../Api/Supplier';
import { useNavigate } from "react-router-dom";

function Add_supplier() {
    const navigate = useNavigate();
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const [countries, setCountries] = useState();
    const [availableCountries, setAvailableCountries] = useState();
    const [selectedCountryFromAvailable, setSelectedCountryFromAvailable] = useState([])
    const [selectedCountryName, setSelectedCountryName] = useState('')
    const [ApiCheck, setApiCheck] = useState(false);
    const [ActiveCheck, setActiveCheck] = useState(false);
    useEffect(() => {
        getCountries()
    }, [])

    const getCountries = async () => {
        let res = await masterData({ moduleName: 'country' })
        if (res.status === 1) {
            setCountries(res.payload)
            let avlCountry = res.payload
            avlCountry.unshift({id:0, name:'All', value:"all"})
            setAvailableCountries(avlCountry)
        }
    }
    const setField = (field, value) => {
        setForm({ ...form, [field]: value })
        if (!!errors[field])
            setErrors({ ...errors, [field]: null })
    }
    const validateForm = () => {
        const { supplierName, website, countryID, completeURL, terminateURL, overQuotaURL, qualityTeamURL, surveyCloseURL } = form;
        const newErrors = {};
        if (!supplierName || supplierName === '') newErrors.supplierName = 'Please enter client name';
        if (!website || website === '') newErrors.website = 'Please enter this field';
        if (!countryID || countryID === 'Select Country') newErrors.countryID = 'Please select country';
        if (!completeURL || completeURL === '') newErrors.completeURL = 'Please enter this field';
        if (!terminateURL || terminateURL === '') newErrors.terminateURL = 'Please enter this field';
        if (!overQuotaURL || overQuotaURL === '') newErrors.overQuotaURL = 'Please enter this field';
        if (!qualityTeamURL || qualityTeamURL === '') newErrors.qualityTeamURL = 'Please enter this field';
        if (!surveyCloseURL || surveyCloseURL === '') newErrors.surveyCloseURL = 'Please enter this field';
        return newErrors
    }
    const handlesubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm()
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors)
        } else {
            let res = await SupplierCreate({ payload: form })
            if (res.status === 1) {
                navigate("/supplier");
            } else {
                setErrors({ ...errors, ['submit']: res.message })
            }
        }
    }

    const onSelect = (selectedValues) => {
        selectedValues.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
        if(selectedValues[0].id === 0){
            selectedValues = countries
            selectedValues[0] = {id:0, name:"Remove All", value:"removeAll"}
        }
        setSelectedCountryFromAvailable(selectedValues)
        let selectedCountryId = Array.isArray(selectedValues) ? selectedValues.map(x => x.id) : [];
        setField('allowedCountriesID', selectedCountryId)
        setSelectedCountryName(Array.isArray(selectedValues) ? selectedValues.map(x => x.name !== 'Remove All' ? x.name : '') : [])
    }

    const onRemove = (selectedValues) => {
        selectedValues.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
        if((form?.allowedCountriesID[0] === 0 && selectedValues[0]?.id !== 0)){
            setSelectedCountryFromAvailable([])
            selectedValues = [];
            setField('allowedCountriesID', [])
            setSelectedCountryName([])
        }else{
            let selectedCountryId = Array.isArray(selectedValues) ? selectedValues.map(x => x.id) : [];
            setField('allowedCountriesID', selectedCountryId)
            setSelectedCountryName(Array.isArray(selectedValues) ? selectedValues.map(x => x.name) : [])
        }
    }


    const handleApiCheck = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setApiCheck(true)
        }

        if (!checked) {
            setApiCheck(false)
        }
    }

    const handleActiveCheck = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setActiveCheck(true)
        }

        if (!checked) {
            setActiveCheck(false)
        }
    }

    useEffect(() => {
        document.title = "Add Supplier - Mindforce"
    },);



    return (
        <div>
            {/* <Sidebar /> */}
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            <div className="row">
                                <div className="col-12 col-lg-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <div>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a >Supplier</a></li>
                                                <li className="breadcrumb-item active">Add Supplier</li>
                                            </ol>
                                        </div>
                                        <h4 className="mb-sm-0">Add supplier</h4>
                                        </div>
                                        <NavLink to={'/supplier'} className ="btn btn-md btn-info" > Back To Suppliers List </NavLink>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}

                            <div className="row">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">

                                        {/* <!-- end card header --> */}

                                        <div className="card-body">
                                            <div className="">
                                                <form >
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="mb-3 required_field">{errors?.submit}</div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-check mb-3" style={{ float: "right" }}>
                                                                <input className="form-check-input" type="checkbox" id="formCheckActive" onClick={(e) => handleActiveCheck(e)} />
                                                                <label className="form-check-label"htmlFor="formCheckActive">
                                                                    Active
                                                                </label>
                                                            </div>
                                                            <div className="form-check mb-3" style={{ float: "right", marginRight:"10px" }}>
                                                                <input className="form-check-input" type="checkbox" id="formCheck1" onClick={(e) => handleApiCheck(e)} />
                                                                <label className="form-check-label"htmlFor="formCheck1">
                                                                    API
                                                                </label>
                                                            </div>
                                                            
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Supplier Name <span className="required_field">*{errors.supplierName}</span></label>
                                                                <input type="text" className="form-control" placeholder="Enter supplier name" id="supplierName" defaultValue={form.supplierName} onChange={(e) => setField('supplierName', e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Supplier Website <span className="required_field">*{errors.website}</span></label>
                                                                <input type="text" className="form-control" placeholder="Enter website url" id="website" defaultValue={form.website} onChange={(e) => setField('website', e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="ForminputState" className="form-label mb-0">Country <span className="required_field">*{errors.countryID}</span></label>
                                                                <select className="form-select" data-choices data-choices-sorting="true" id="Country" onChange={(e) => setField('countryID', parseInt(e.target.value))} >
                                                                    <option disabled selected>Select Country</option>
                                                                    {countries?.map((country) => {
                                                                        return (<option value={country.id}>{country.name}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Email ID </label>
                                                                <input type="email" className="form-control" placeholder="Enter email" id="emailID" defaultValue={form?.email} onChange={(e) => setField('email', e.target.value)} />
                                                            </div>
                                                        </div>
                                                        {/* <!--end col--> */}
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="ProjectManager" className="form-label mb-0">Contact Number </label>
                                                                <input type="text" className="form-control" placeholder="Enter number" id="contactNumber" defaultValue={form?.contactNumber} onChange={(e) => setField('contactNumber', e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="ProjectManager" className="form-label mb-0">Panel Size </label>
                                                                <input type="text" className="form-control" placeholder="Enter number" id="panelSize" defaultValue={form?.panelSize} onChange={(e) => setField('panelSize', parseInt(e.target.value))} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Complete <span className="required_field">*{errors.completeURL}</span></label>
                                                                <input type="text" className="form-control" placeholder="Enter " id="completeURL" value={form.completeURL} onChange={(e) => setField('completeURL', e.target.value)} />
                                                                <span className="max_char">Link ex. https://gts.bytesbrick.com/thanks?status=c&identifier=[IDENTIFIER]</span>

                                                            </div>
                                                        </div>
                                                        {/* <!--end col--> */}
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="ProjectManager" className="form-label mb-0"> Terminate <span className="required_field">*{errors.terminateURL}</span></label>
                                                                <input type="text" className="form-control" placeholder="Enter" id="terminateURL" value={form.terminateURL} onChange={(e) => setField('terminateURL', e.target.value)} />
                                                                <span className="max_char">Link ex. https://gts.bytesbrick.com/thanks?status=c&identifier=[IDENTIFIER]</span>

                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="ProjectManager" className="form-label mb-0">Over Quota <span className="required_field">*{errors.overQuotaURL}</span></label>
                                                                <input type="text" className="form-control" placeholder="Enter " id="overQuotaURL" value={form.overQuotaURL} onChange={(e) => setField('overQuotaURL', e.target.value)} />
                                                                <span className="max_char">Link ex. https://gts.bytesbrick.com/thanks?status=c&identifier=[IDENTIFIER]</span>

                                                            </div>
                                                        </div>
                                                        {/* <!--end col--> */}
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Quality Team <span className="required_field">*{errors.qualityTeamURL}</span></label>
                                                                <input type="text" className="form-control" placeholder="Enter " id="qualityTeamURL" value={form.qualityTeamURL} onChange={(e) => setField('qualityTeamURL', e.target.value)} />
                                                                <span className="max_char">Link ex. https://gts.bytesbrick.com/thanks?status=c&identifier=[IDENTIFIER]</span>

                                                            </div>
                                                        </div>
                                                        {/* <!--end col--> */}
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="ProjectManager" className="form-label mb-0">Survey Close <span className="required_field">*{errors.surveyCloseURL}</span></label>
                                                                <input type="text" className="form-control" placeholder="Enter " id="surveyCloseURL" value={form.surveyCloseURL} onChange={(e) => setField('surveyCloseURL', e.target.value)} />
                                                                <span className="max_char">Link ex. https://gts.bytesbrick.com/thanks?status=c&identifier=[IDENTIFIER]</span>

                                                            </div>
                                                        </div>
                                                        {/* <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="ProjectManager" className="form-label mb-0">Post Back Url </label>
                                                                <input type="text" className="form-control" placeholder="Enter " id="postbackURL" value={form.postbackURL} onChange={(e) => setField('postbackURL', e.target.value)} />
                                                                <span className="max_char">Link ex. https://gts.bytesbrick.com/thanks?status=c&identifier=[IDENTIFIER]</span>
                                                            </div>
                                                        </div> */}

                                                        {/* <!--end col--> */}
                                                        <div className="col-md-12">
                                                            <div className="mb-3">
                                                                <label htmlFor="aboutSupplier" className="form-label mb-0">About Supplier </label>
                                                                <textarea className="form-control" id="aboutSupplier" rows="3" onChange={(e) => setField('aboutSupplier', e.target.value)}>{form.aboutSupplier}</textarea>
                                                                <span className="max_char">Maximum limit of characters 250</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="mb-3 optionListContainer_div">
                                                                <label htmlFor="ForminputState" className="form-label mb-0">Allowed Countries <span className="required_field">*</span></label>
                                                                <Multiselect
                                                                    showArrow
                                                                    options={availableCountries}
                                                                    displayValue="name"
                                                                    onSelect={onSelect}
                                                                    onRemove={onRemove}
                                                                    showCheckbox
                                                                    selectedValues={selectedCountryFromAvailable}
                                                                    placeholder={`${selectedCountryName?.length} selected`}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="mb-3">
                                                                <label htmlFor="ProjectManager" className="form-label mb-0">Selected Countries </label>
                                                                <input type="text" className="form-control country_allowed" value={selectedCountryName + " "} placeholder="Enter size" id="ProjectManager" />
                                                            </div>
                                                        </div>
                                                        {ApiCheck &&
                                                        <>
                                                            <div className="col-md-6">
                                                                <div className="mb-3">
                                                                    <label htmlFor="ProjectManager" className="form-label mb-0">API Url </label>
                                                                    <input type="text" className="form-control" placeholder="Enter " id=""  />
                                                                </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="mb-3">
                                                                        <label htmlFor="ProjectManager" className="form-label mb-0">API Key </label>
                                                                        <input type="text" className="form-control" placeholder="Enter " id=""  />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="mb-3">
                                                                        <label htmlFor="ProjectManager" className="form-label mb-0">Secret Key </label>
                                                                        <input type="text" className="form-control" placeholder="Enter " id=""  />
                                                                    </div>
                                                                </div>


                                                                <div className="col-md-12">
                                                            <div className="mb-3">
                                                                <label htmlFor="apiBody" className="form-label mb-0">API Body </label>
                                                                <textarea className="form-control" id="apiBody" rows="3"></textarea>
                                                            
                                                            </div>
                                                        </div>

                                                                </>

                                                        }
                                                        <div className="col-lg-12">
                                                            <div className="text-end">
                                                                <button type="submit" onClick={handlesubmit}className="btn btn-primary mx-2">Submit</button>
                                                                <button type="Cancel" className="btn btn-secondary">Cancel</button>
                                                            </div>
                                                        </div>

                                                        {/* <!--end col--> */}
                                                    </div>
                                                    {/* <!--end row--> */}
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                {/* <!-- end col --> */}









                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Add_supplier