
import './App.css'
import 'rsuite/dist/rsuite.min.css';
import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './component/Dashboard';
import Login_page from './component/Login_page';
import Single_project from './component/Project/Single_project';
import Group_project from './component/Project/Group_project';
import Client from './component/Client/Client';
import Add_client from './component/Client/Add_client';
import Edit_client from './component/Client/Edit_client';
import Supplier from './component/Supplier/Supplier';
import Add_supplier from './component/Supplier/Add_supplier';
import Question from './component/Library/Question';
import All_project from './component/Project/All_project';
import Project_report from './component/Client/Report/Project_report';
import Client_report from './component/Client/Report/Client_report';
import Supplier_report from './component/Client/Report/Supplier_report';
import Group_report from './component/Client/Report/Group_report';
import Statistics_report from './component/Client/Report/Statistics_report';
import RD_report from './component/Client/Report/RD_report';
import ProjectActivity_report from './component/Client/Report/ProjectActivity_report';
import Ip_tracker from './component/Support/Ip_tracker';
import Reconciliation from './component/Support/Reconciliation';
import Block_ip_list from './component/Support/Blocked_ip_list';
import Redirect_status from './component/Support/Redirect_status';
import Users from './component/Admin/Users';
import Edit_supplier from './component/Supplier/Edit_supplier';
import Single_project_edit from './component/Project/Single_project_edit';
import Clone_project from './component/Project/Clone_project';
import Group_project_child_add from './component/Project/Group_project_child_add';
import Group_project_edit from './component/Project/Group_project_edit';
import Project_detail from './component/Project/Project_detail';
import Group_project_child_detail from './component/Project/Group_project_child_detail';
import Group_project_child_clone from './component/Project/Group_project_child_clone';
import Recontact from './component/Project/Recontact';
import Group_project_child_edit from './component/Project/Group_project_child_edit';
import Prescreen from './component/Library/Prescreen';
import Prescreen_update from './component/Library/Prescreen_update';

import Role from './component/URM/Role';
import Module from './component/URM/Module';
import Action from './component/URM/Action';
import User from './component/URM/User';
import Sidebar from './component/Layout/Sidebar';

function App() {
  return (
    <div className="App">
      <>
        {
            window.location.pathname !="/"&&
            <Sidebar />
        }
        <div className="master-wrapper">
        <Routes>
          <Route path='/' element={<Login_page />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/single-project' element={<Single_project />} />
          <Route path='/single-project-edit' element={<Single_project_edit />} />
          <Route path='/clone-project' element={<Clone_project />} />
          <Route path='/group-project' element={<Group_project />} />
          <Route path='/group-project-edit' element={<Group_project_edit />} />
          <Route path='/group-project-child-add' element={<Group_project_child_add />} />
          <Route path='/group-project-child-edit' element={<Group_project_child_edit />} />
          <Route path='/group-project-child-clone' element={<Group_project_child_clone />} />
          <Route path='/group-project-child-detail' element={<Group_project_child_detail />} />
          <Route path='/client' element={<Client />} />
          <Route path='/add-client' element={<Add_client />} />
          <Route path='/client-edit' element={<Edit_client />} />
          <Route path='/supplier' element={<Supplier />} />
          <Route path='/add-supplier' element={<Add_supplier />} />
          <Route path='/supplier-edit' element={<Edit_supplier />} />
          <Route path='/question' element={<Question />} />
          <Route path='/all-project' element={<All_project />} />
          <Route path='/project-report' element={<Project_report />} />
          <Route path='/client-report' element={<Client_report />} />
          <Route path='/supplier-report' element={<Supplier_report />} />
          <Route path='/rd-report' element={<RD_report />} />
          <Route path='/project-activity-report' element={<ProjectActivity_report />} />
          <Route path='/group-project-report' element={<Group_report />} />
          <Route path='/statistics-report' element={<Statistics_report />} />
          <Route path='/ip-tracker' element={<Ip_tracker />} />
          <Route path='/reconciliation' element={<Reconciliation />} />
          <Route path='/blocked-ip' element={<Block_ip_list />} />
          <Route path='/redirect-status' element={<Redirect_status />} />
          <Route path='/users' element={<Users />} />
          <Route path='/single-project-detail' element={<Project_detail />} />
          <Route path='/recontact' element={<Recontact/>} />
          <Route path='/prescreen' element = {<Prescreen/>} />
          <Route path='/update-prescreen-template' element = {<Prescreen_update/>} />

          <Route path='/roles' element={<Role />} />
          <Route path='/modules' element={<Module />} />
          <Route path='/actions' element={<Action />} />
          <Route path='/users1' element={<User />} />
          
        
        </Routes>
        </div>
      </>
    </div>
  );
}

export default App;
