import React from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import Sidebar from "../Layout/Sidebar";
import { useState, useEffect } from "react";
import { defaultPage } from "../../Common/Constant";
import Pagination from "../Layout/Pagination";
import { IPDelete, IPList } from "../../Api/IP";

function Block_ip_list() {
  const [blockedIPs, setBlockedIPs] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageInfo, setPageInfo] = useState({});
  const [dataCount, setDataCount] = useState();

  const fetchBlockedIPs = async () => {
    try {
      const response = await IPList({
        page: pageInfo.current_page,
        recPerPage: defaultPage.perPage,
        searchTerm: searchTerm,
      });

      if (response.status === 1) {
        setBlockedIPs(response.payload);
        setPageInfo(response.paginationInfo);
        setDataCount(response.dataCount);
      } else {
        setBlockedIPs([]);
      }
    } catch (error) {
      console.error("Error fetching blocked IPs:", error);
      setBlockedIPs([]);
      setTotalRecords(0);
    }
  };

  const changePage = (p) => {
    pageInfo.current_page = p;
    fetchBlockedIPs();
  };

  const handleDelete = async (id) => {
    try {
      const response = await IPDelete(id);
      if (response.status === 1) {
        fetchBlockedIPs();
      } else {
        console.error("Error deleting blocked IP:", response.message);
      }
    } catch (error) {
      console.error("Error deleting blocked IP:", error);
    }
  };

  useEffect(() => {
    document.title = "Blocked IPs - Mindforce";
    fetchBlockedIPs();
  }, []);

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    await fetchBlockedIPs();
  };

  return (
    <div>
      {/* <Sidebar /> */}
      <Header />
      <div id="layout-wrapper">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <div>
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <a>Support</a>
                        </li>
                        <li className="breadcrumb-item active">
                          Blocked IP List
                        </li>
                      </ol>
                    </div>
                    <h4 className="mb-sm-0">Blocked IP List</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="report">
                        <form onSubmit={handleSearchSubmit}>
                          <div className="row">
                            <div className="row justify-content-between mt-3">
                              <div className="col-md-2 col-lg-2">
                                {/* <div className="mb-3 d-flex page_size_support">
                                  <label
                                    htmlFor="PageSize"
                                    className="form-label mb-0 mt-1"
                                  >
                                    Page Size:{" "}
                                  </label>
                                  <select
                                    id="PageSize"
                                    className="form-select"
                                    data-choices
                                    data-choices-sorting="true"
                                    onChange={(e) =>
                                      setPageSize(Number(e.target.value))
                                    }
                                  >
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={30}>30</option>
                                  </select>
                                </div> */}
                              </div>
                              <div className="col-md-4 col-lg-4">
                                <div className="text-end">
                                  <div className="search">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Search..."
                                      autoComplete="off"
                                      id="search-options"
                                      value={searchTerm}
                                      onChange={(e) =>
                                        setSearchTerm(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <table className="table table-striped table-hover table_client mt-3">
                              <thead>
                                <tr
                                  className="bg-primary-1"
                                  style={{ color: "white" }}
                                >
                                  <th scope="col">Sr. No.</th>
                                  <th scope="col">IP</th>
                                  <th scope="col">Block Date</th>
                                  <th scope="col">Comments</th>
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {blockedIPs.length > 0 ? (
                                  blockedIPs.map((ip, index) => (
                                    <tr key={ip.ID}>
                                      <th scope="row">
                                        {(page - 1) * pageSize + index + 1}
                                      </th>
                                      <td>{ip.Ipv4}</td>
                                      <td>{ip.createdOn}</td>
                                      <td>{ip.comment}</td>
                                      <td>
                                        <i
                                          className="ri-delete-bin-line"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => handleDelete(ip.ID)}
                                        />
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="5" className="text-center">
                                      No Blocked IPs Found
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                            <div className="pagination-wrapper">
                              <Pagination
                                pageInfo={pageInfo}
                                changePage={changePage}
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Block_ip_list;
