import React, { useState, useEffect } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import Sidebar from "../Layout/Sidebar";
import { useNavigate } from "react-router-dom";
import Pagination from "../Layout/Pagination";
import { defaultPage } from "../../Common/Constant";

import {
  reconciliationList,
  getSingleReconciliationDownlodReport,
} from "../../Api/reconciliation";

const Reconciliation = () => {
  const navigate = useNavigate();
  const [mfIdentifier, setMfIdentifier] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [pageInfo, setPageInfo] = useState({});
  const [dataCount, setDataCount] = useState();

  useEffect(() => {
    document.title = "Reconciliation - Mindforce";
  }, [page, perPage, searchTerm, mfIdentifier]);

  useEffect(() => {
    fetchReconciliationData();
  }, [page, perPage, searchTerm, mfIdentifier]);

  const fetchReconciliationData = async () => {
    try {
      const response = await reconciliationList({
        mfIdentifier: mfIdentifier,
        page: pageInfo.current_page,
        recPerPage: defaultPage.perPage,
        searchTerm: searchTerm,
      });

      if (response.status === 1) {
        setData(response.payload || []);
        setPageInfo(response.paginationInfo);
        setDataCount(response.dataCount);
      } else {
        console.error("Error fetching reconciliation data:", response.message);
        setData([]);
      }
    } catch (error) {
      console.error("Error fetching reconciliation data:", error);
      setData([]);
      setTotalRecords(0);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    // setPage(1);
    fetchReconciliationData();
  };

  const handleClear = () => {
    setMfIdentifier("");
    setSearchTerm("");
    setPage(1);
    setPerPage(10);
    setData([]);
  };

  const handleDownloadReport = async () => {
    try {
      const response = await getSingleReconciliationDownlodReport({
        mfIdentifier,
      });

      if (response.payload && response.payload.reportURL) {
        const link = document.createElement("a");
        link.href = response.payload.reportURL;
        link.setAttribute("download", "reconciliation_report.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("No URL returned from API");
      }
    } catch (error) {
      console.error("Error downloading reconciliation report:", error);
    }
  };

  const changePage = (p) => {
    pageInfo.current_page = p;
    fetchReconciliationData();
  };

  return (
    <div>
      {/* <Sidebar /> */}
      <Header />
      <div id="layout-wrapper">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <div>
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <a>Support</a>
                        </li>
                        <li className="breadcrumb-item active">
                          Reconciliation
                        </li>
                      </ol>
                    </div>
                    <h4 className="mb-sm-0">Reconciliation</h4>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="report">
                        <form onSubmit={handleSearch}>
                          <div className="row">
                            <div className="col-md-6 col-lg-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="mfIdentifier"
                                  className="form-label mb-0"
                                >
                                  Search Identifiers
                                </label>
                                <textarea
                                  className="form-control"
                                  id="mfIdentifier"
                                  rows="3"
                                  value={mfIdentifier}
                                  onChange={(e) =>
                                    setMfIdentifier(e.target.value)
                                  }
                                ></textarea>
                              </div>
                            </div>

                            <div className="col-md-6 col-lg-6">
                              <div className="mb-3">
                                <div className="mt-8_rec">
                                  <button
                                    type="submit"
                                    className="btn btn-primary mx-2"
                                  >
                                    Search
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-primary mx-2"
                                    onClick={handleClear}
                                  >
                                    Clear
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-primary mx-2"
                                    disabled
                                  >
                                    Reconcile
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-primary mx-2"
                                    disabled
                                  >
                                    Complete
                                  </button>
                                  <button
                                    type="button"
                                    className={`btn btn-primary mx-2 ${
                                      data.length === 0 ? "disabled" : ""
                                    }`}
                                    onClick={handleDownloadReport}
                                    disabled={data.length === 0}
                                  >
                                    <i className="ri-folder-download-fill"></i>{" "}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row justify-content-end mt-3">
                            <div className="col-md-4 col-lg-4">
                              <div className="text-end">
                                <div className="search">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search..."
                                    autoComplete="off"
                                    id="search-options"
                                    value={searchTerm}
                                    onChange={(e) =>
                                      setSearchTerm(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="table-responsive">
                            <table className="table table-striped table-hover table_client mt-3">
                              <thead>
                                <tr
                                  className="bg-primary-1"
                                  style={{ color: "white" }}
                                >
                                  <th scope="col">Sr.No.</th>
                                  <th scope="col">Action</th>
                                  <th scope="col">ProjectCode</th>
                                  <th scope="col">ProjectName</th>
                                  <th scope="col">Supplier</th>
                                  <th scope="col">SupplierIdentifier</th>
                                  <th scope="col">UserIdentifier</th>
                                  <th scope="col">Status</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Geo.Location</th>
                                  <th scope="col">DeviceType</th>
                                  <th scope="col">BrowserDetail</th>
                                  <th scope="col">IsTestLink</th>
                                </tr>
                              </thead>
                              <tbody>
                                {data.length > 0 ? (
                                  data.map((item, index) => (
                                    <tr key={index}>
                                      <th scope="row">
                                        {(page - 1) * perPage + index + 1}
                                      </th>
                                      <td></td>
                                      <td>{item.projectCode}</td>
                                      <td>{item.projectName}</td>
                                      <td>{item.supplier}</td>
                                      <td>{item.supplierIdentifier}</td>
                                      <td>{item.userIdentifier}</td>
                                      <td>{item.status}</td>
                                      <td>{item.date}</td>
                                      <td>{item.geoLocation}</td>
                                      <td>{item.deviceType}</td>
                                      <td>{item.browserDetails}</td>
                                      <td>
                                      <div className="form-check ">
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  id="formCheck1"
                                                  {...(item?.testLink
                                                    ? { checked: true }
                                                    : { checked: false })}
                                                />
                                              </div>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="13" className="text-center">
                                      No data found
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>

                          <div className="pagination-wrapper">
                            <Pagination
                              pageInfo={pageInfo}
                              changePage={changePage}
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Reconciliation;
