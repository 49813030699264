import React, { useState } from 'react'
import Footer from '../Layout/Footer'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import { useEffect } from 'react'
import { masterData, ClientList } from '../../Api/Master'
import { ProjectCreateSingle, ProjectDetail, cloneData, validateFormData } from '../../Api/Project'
import { useNavigate } from "react-router-dom";
import {useLocation} from 'react-router-dom';
import { format } from 'date-fns';
import { userList } from '../../Api/User'

function Clone_project(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const queryString = new URLSearchParams(location.search)
    const id = queryString.get('id')
    useEffect(() => {
        document.title = "Single Project - Mindforce"
    },);

    const [clients, setClients] = useState();
    const [projectManagers, setProjectManager] = useState();
    const [countries, setCountries] = useState();
    const [languages, setLanguages] = useState();
    const [projectCategories, setProjectCategories] = useState();
    const [currencies, setCurrencies] = useState();
    const [projectFilters, setProjectFilters] = useState();
    const [showUniqueIp, setShowUniqueIp] = useState(false)
    const [uniqueIP, setUniqueIp] = useState();
    const [showSpeeder, setShowSpeeder] = useState();
    const [uniqueIPError, setUniqueIPError] = useState()
    const [speeder, setSpeeder] = useState();
    const [speederError, setSpeederError] = useState()
    const [selectedProjectFileter, setSelectedProjectFilter] = useState([])
    const [securityFilters, setSecurityFilters] = useState();
    const [selectedSecurityFilter, setSelectedSecurityFilter] = useState([])
    const [deviceFilters, setDeviceFilters] = useState();
    const [deviceFilterError, setDeviceFilterError] = useState()
    const [selectedDeviceFilter, setSelectedDeviceFilter] = useState([])
    const [startDate, setStartDate]= useState()
    const [endDate, setEndDate] = useState();
    const [detail, setDetail] = useState();
    const [form, setForm] = useState();
    const [errors, setErrors] = useState({});

    useEffect(() => {
        projectDetailSingle()
    },[])
    useEffect(() => {
        getClients()
        getCountries()
        getProjectCategories()
        getCurrencies()
        getProjectManagers()
    },[])

    useEffect(() => {
        getLanguages()
    },[form?.countryID])

    useEffect(() => {
        getProjectFilters()
        getSecurityFilters()
        getDeviceFilter()
    },[])


    const getProjectManagers = async() => {
        let res = await userList({page:0, searchKeyword:'',role:"project-manager"});

        if(res.status === 1){
            setProjectManager(res.payload)
        }        
    }
    
    const getClients = async() => {
        let res = await ClientList({page:0, searchKeyword:''})
        if(res.status === 1){
            setClients(res.payload)
        }
    }

    const getCountries = async() => {
        let res = await masterData({moduleName:'country'})
        if(res.status === 1){
            setCountries(res.payload)
        }
    }

    const getLanguages = async() => {
        let res = await masterData({moduleName:'language', countryID:parseInt(form?.countryID)})
        if(res?.status === 1){
            setLanguages(res.payload)
        }else if(res?.status === 0){
            if(res?.messageCode === 'EMPTY_RESPONSE'){
                setLanguages()
            }
        }
    }

    const getProjectCategories = async() => {
        let res = await masterData({moduleName:'product-category'})
        if(res.status === 1){
            setProjectCategories(res.payload)
        }
    }

    const getCurrencies = async() => {
        let res = await masterData({moduleName:'currency'})
        if(res.status === 1){
            setCurrencies(res.payload)
        }
    }

    const getProjectFilters = async() => {
        let res = await masterData({moduleName:'project-filters'})
        if(res.status === 1){
            setProjectFilters(res.payload)
        }
    }

    const getSecurityFilters = async() => {
        let res = await masterData({moduleName:'security-filters'})
        if(res.status === 1){
            setSecurityFilters(res.payload)
        }
    }

    const getDeviceFilter = async() => {
        let res = await masterData({moduleName:'device-filters'})
        if(res.status === 1){
            setDeviceFilters(res.payload)
        }
    }

    const projectDetailSingle = async () => {
        let res = await ProjectDetail({payload:id})
        if(res.status === 1){
            setStartDate(format(new Date(res.payload[0]?.startDate*1000), 'yyyy-MM-dd'))
            setEndDate(format(new Date(res.payload[0]?.endDate*1000), 'yyyy-MM-dd'))
            await setForm(res.payload[0])
            let pfArray = [];
            let dfArray = [];
            let sfArray = [];
            res.payload[0]?.projectFilters?.forEach((pf, i) => {
                if(pf.status === 1){
                    if(pf.id === 420){
                        setUniqueIp(pf.value)
                        setShowUniqueIp(true)
                    }if(pf.id === 421){
                        setSpeeder(pf.value)
                        setShowSpeeder(true)
                    }
                    pfArray.push(pf.id.toString())
                }
            })
            res.payload[0]?.deviceFilters?.forEach((df, i) => {
                if(df.status === 1){
                    dfArray.push(df.id.toString())
                }
            })
            res.payload[0]?.securityFilters?.forEach((sf, i) => {
                if(sf.status === 1){
                    sfArray.push(sf.id.toString())
                }
            })
            await setSelectedProjectFilter(pfArray)
            await setSelectedSecurityFilter(sfArray)
            await setSelectedDeviceFilter(dfArray)
        }
    }

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        })
        if (!!errors[field])
            setErrors({
                ...errors,
            [field]: null
        })
    }

    const handleDates = (e, type) => {
        let date = Math.floor(new Date(e.target.value?.toString()).getTime()/1000)
        switch (type) {
            case 'startDate':
                form.startDate = date
                setStartDate(format(new Date(date*1000), 'yyyy-MM-dd'))
                break;
            case 'endDate':
                form.endDate = date
                setEndDate(format(new Date(date*1000), 'yyyy-MM-dd'))
                break;
        
            default:
                break;
        }
    }
    const handleProjectFilter = (e) => {
        const {value, checked} = e.target;
        const slug = e.target.dataset?.key
        // console.log(slug)
        if (checked) {
            if(slug === 'unique-ip'){
                setUniqueIp(1)
                setShowUniqueIp(true)
            }
            if(slug === 'speeder'){
                setShowSpeeder(true)
            }
            if(slug === 'captcha'){
                // document.getElementById('projectFilter'+418).checked = true;
                document.getElementById('projectFilter'+418).click();
            }
            setSelectedProjectFilter([...selectedProjectFileter, value]);
        } else {
            if(slug === 'unique-ip'){
                setUniqueIp(null)
                setShowUniqueIp(false)
            }
            if(slug === 'speeder'){
                setShowSpeeder(false)
            }
            if(slug === 'captcha'){
                // document.getElementById('projectFilter'+418).checked = false;
                document.getElementById('projectFilter'+418).click();
            }
            setSelectedProjectFilter(selectedProjectFileter.filter((s) => s !== value));
        }
    }

    const handleSecurityFilter = (e) => {
        const {value, checked} = e.target;
        if (checked) {
            setSelectedSecurityFilter([...selectedSecurityFilter, value]);
        } else {
            setSelectedSecurityFilter(selectedSecurityFilter.filter((s) => s !== value));
        }
    }

    const handleDeviceFilter = (e) => {
        const {value, checked} = e.target;
        if (checked) {
            setSelectedDeviceFilter([...selectedDeviceFilter, value]);
        } else {
            setSelectedDeviceFilter(selectedDeviceFilter.filter((s) => s !== value));
        }
    }

    const handlesubmit = async (e) => {
        e.preventDefault();
        setErrors()
        setSpeederError()
        setUniqueIPError()
        setDeviceFilterError()
        let uniqueIpErrorCount = 0;
        let speederErrorCount = 0;
        let proFilter = [];
        let value = null;
        projectFilters?.forEach(p => {
            let status = 1
            if(selectedProjectFileter.indexOf(p.id.toString()) === -1){
                status = 0;
            }
            if(p.id === 420 && status===1){
                if(uniqueIP){
                    value = uniqueIP
                }else{
                    uniqueIpErrorCount = uniqueIpErrorCount + 1;
                    value = null
                }
                
            }
            else if(p.id === 421 && status===1){
                if(speeder){
                    value=speeder
                }else{
                    speederErrorCount = speederErrorCount + 1;
                    setSpeederError("Required")
                    value = null
                }
                // value = speeder
                
            }else{
                value = null;
            }
            proFilter.push({id:p.id, status:status, value:value})
        });

        // console.log(uniqueIPError)

        let secFilter = [];
        securityFilters?.forEach(s => {
            let status = 1
            if(selectedSecurityFilter.indexOf(s.id.toString()) === -1){
                status = 0;
            }
            secFilter.push({id:s.id, status:status, value:null})
        });

        let devFilter = [];
        let isDeviceFilterExist = deviceFilters?.length;
        deviceFilters?.forEach(d => {
            let status = 1
            if(selectedDeviceFilter.indexOf(d.id.toString()) === -1){
                status = 0;
                isDeviceFilterExist = isDeviceFilterExist-1;
            }
            devFilter.push({id:d.id, status:status, value:null})
        });
        form.projectFilters = proFilter;
        form.securityFilters = secFilter;
        form.deviceFilters = devFilter
        const formErrors = validateFormData(form)
        if(Object.keys(formErrors).length > 0){
            setErrors(formErrors)
        }else{
            if(isDeviceFilterExist < 1){
                setDeviceFilterError("Required")
                return false;
            }
            if(uniqueIpErrorCount > 0){
                setUniqueIPError("Required")
                return false;
            }
            if(speederErrorCount > 0){
                setSpeederError("Required")
                return false;
            }
            let res = await ProjectCreateSingle({payload:form});
            if(res.status === 1){
                let projectDetailID = res?.payload.projectDetailID;
                let payload = {
                    "sourceProjectDetailID": id,
                    "destinationProjectDetailID": projectDetailID,
                    "cloneType": "prescreener"
                }
                res = await cloneData({payload});
                navigate("/single-project-detail?id=" + projectDetailID);
            }else{
                setErrors({...errors,['submit']:res.message})
            }
        }
        
    }
    return (
        <div>
            {/* <Sidebar /> */}
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            <div className="row">
                                <div className="col-12 col-lg-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <div>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a>Project</a></li>
                                                <li className="breadcrumb-item active">Clone Project</li>
                                            </ol>
                                        </div>
                                        <h4 className="mb-sm-0">Clone Project</h4>
                                        </div>
                                        

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}

                            <div className="row">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">

                                        {/* <!-- end card header --> */}

                                        <div className="card-body">
                                            <div className="">
                                                <form >
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="ForminputState" className="form-label mb-0">Client <span className="required_field">*{errors?.clientID}</span></label>
                                                                <select id="ForminputState" disabled={true} className="form-select" data-choices data-choices-sorting="true" onChange={(e) => setField('clientID',e.target.value)}>
                                                                    <option value={""} disabled selected>Select Client</option>
                                                                    {clients?.map((client) => {
                                                                        return (<option value={client.clientID} selected={client.id === form?.clientId}>{client.clientCode +' : '+ client.clientName}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="ProjectName" className="form-label mb-0">Project Name <span className="required_field">*{errors?.fullName}</span></label>
                                                                <input type="text" className="form-control" placeholder="Enter project name" id="fullName" defaultValue={form?.fullName}  onChange={(e) => setField('fullName',e.target.value)} />
                                                            </div>
                                                        </div>
                                                        {/* <!--end col--> */}
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="ProjectManager" className="form-label mb-0">Project Manager <span className="required_field">*{errors?.managerID}</span></label>
                                                                {/* <input type="text" className="form-control" placeholder="Enter field" id="managerName" defaultValue={form?.managerName} onChange={(e) => setField('managerName',e.target.value)} /> */}
                                                                <select id="managerID" className="form-select" data-choices data-choices-sorting="true"  defaultValue={form?.managerID} onChange={(e) => setField('managerID',e.target.value)}>
                                                                    <option value={""} disabled selected>Select Project Manager</option>
                                                                    {projectManagers?.map((manager) => {
                                                                        return (<option value={manager.ID} selected = {form?.managerID == manager.ID? true:false} >{manager.firstName+" "+manager.lastName}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {/* <!--end col--> */}
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="ForminputState" className="form-label mb-0">Project Country <span className="required_field">*{errors?.countryID}</span></label>
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" onChange={(e) => setField('countryID',e.target.value)}>
                                                                    <option value={""} disabled selected>Select Country</option>
                                                                    {countries?.map((country) => {
                                                                        return (<option value={country.id}>{country.name}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="ForminputState" className="form-label mb-0">Project Language <span className="required_field">*{errors?.languageID}</span></label>
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" onChange={(e) => setField('languageID',e.target.value)} disabled={form?.countryID ? false : true}>
                                                                    <option value={""} disabled selected>Select Language</option>
                                                                    {languages?.map((language) => {
                                                                        return (<option value={language.id}>{language.name}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="ForminputState" className="form-label mb-0">Project Category <span className="required_field">*{errors?.categoryID}</span></label>
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" onChange={(e) => setField('categoryID',e.target.value)}>
                                                                    <option value={""} disabled selected>Select Category</option>
                                                                    {projectCategories?.map((projectCategory) => {
                                                                        return (<option value={projectCategory.id} selected={projectCategory.id === form?.categoryID ? 'selected' : ''}>{projectCategory.name}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {/* <!--end col--> */}
                                                        <div className="col-md-12">
                                                            <div className="mb-3">
                                                                <label htmlFor="Projectdescription" className="form-label mb-0">Project Description <span className="required_field">{errors?.description}</span></label>
                                                                <input type="text" className="form-control" placeholder="Enter project description" id="description" defaultValue={form?.description} onChange={(e) => setField('description',e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col">
                                                            <div className="mb-3">
                                                                <label htmlFor="loi" className="form-label mb-0">LOI(Minute) <span className="required_field">*{errors?.loi}</span></label>
                                                                <input type="text" id="loi" className="form-control" placeholder="Enter" value={form?.loi} onChange={(e) => setField('loi',e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="mb-3">
                                                                <label htmlFor="ir" className="form-label mb-0">IR(%) <span className="required_field">*{errors?.ir}</span></label>
                                                                <input type="text" id="ir" className="form-control" placeholder="Enter " value={form?.ir} onChange={(e) => setField('ir',e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Sample Size <span className="required_field">*{errors?.sampleSize}</span></label>
                                                                <input type="text" className="form-control" placeholder="Enter " value={form?.sampleSize} onChange={(e) => setField('sampleSize',e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Respondent Click Quota <span className="required_field">*{errors?.respondentClickQuota}</span></label>
                                                                <input type="text" className="form-control" placeholder="0" defaultValue={form?.respondentClickQuota} onChange={(e) => setField('respondentClickQuota',e.target.value)} />
                                                            </div>
                                                        </div>

                                                        {/*  */}
                                                        <div className="col">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Currency <span className="required_field">*{errors?.currencyID}</span></label>
                                                                <select className="form-select" data-choices data-choices-sorting="true" onChange={(e) => setField('currencyID',e.target.value)}>
                                                                    <option value={""} disabled selected>Select </option>
                                                                    {currencies?.map((currency) => {
                                                                        return (<option value={currency.id} selected={currency.id === form?.currencyID ? 'selected' : ''}>{currency.name}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-md-3">
                                                            <div className="mb-3">
                                                                <label htmlFor="projectCPI" className="form-label mb-0">Project CPI <span className="required_field">*{errors?.projectCPI}</span></label>
                                                                <input type="text" className="form-control" placeholder="Enter " id="projectCPI" defaultValue={form?.projectCPI} onChange={(e) => setField('projectCPI',e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="mb-3">
                                                                <label htmlFor="supplierCPI" className="form-label mb-0">Supplier CPI <span className="required_field">*{errors?.supplierCPI}</span></label>
                                                                <input type="text" className="form-control" placeholder="0" id="supplierCPI" defaultValue={form?.supplierCPI} onChange={(e) => setField('supplierCPI',e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="mb-3">
                                                                <label htmlFor="startDate" className="form-label mb-0">Start Date<span className="required_field">*{errors?.startDate}</span></label>
                                                                <input type="date" className="form-control" id="startDate" 
                                                                // defaultValue={"2023-01-04"}
                                                                defaultValue={startDate?.toString()} 
                                                                onChange={(e) => handleDates(e, 'startDate')} />
                                                                <input type="text" className="form-control" value={startDate ? format(new Date(startDate),'dd MMM yyyy') : ''} readOnly={true} style={{position:"absolute", top:"20px", width:"190px", borderRightStyle:"none" }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="mb-3">
                                                                <label htmlFor="endDate" className="form-label mb-0">End Date<span className="required_field">*{errors?.endDate}</span></label>
                                                                <input type="date" className="form-control" id="endDate" defaultValue={endDate?.toString()} onChange={(e) => handleDates(e,'endDate')} />
                                                                <input type="text" className="form-control" value={endDate ? format(new Date(endDate),'dd MMM yyyy') : ''} readOnly={true} style={{position:"absolute", top:"20px", width:"190px", borderRightStyle:"none" }} />
                                                            </div>
                                                        </div>


                                                        <div className="form_divide"><hr ></hr></div>
                                                        <h1 className="project_filter">Project Filter</h1>
                                                        {form?.projectFilters?.map((row, i) => {
                                                            return (
                                                                <div className="col-md-3">
                                                                    <div className="form-check mb-3" style={{display:"flex"}}>
                                                                        <input className="form-check-input" type="checkbox" data-key={projectFilters?.[i]?.slug} id={"projectFilter"+row.id} value={row.id}
                                                                        defaultChecked={row.status === 1 ? true : false}
                                                                        
                                                                        onClick={(e) => handleProjectFilter(e)}
                                                                        style={{marginRight:"4px"}}
                                                                         />
                                                                        <label className="form-check-label"htmlFor={"projectFilter"+row.id} style={{float:"left", marginRight:"4px"}}>
                                                                            {projectFilters?.[i]?.name}
                                                                        </label>
                                                                        {projectFilters?.[i]?.slug =="unique-ip" && showUniqueIp ? (<>
                                                                        <span className="required_field">*{uniqueIPError}</span>
                                                                            <input type="number" className="small-input" style={{height:"20px", width:"50px"}} placeholder="" id="UniqueIP" defaultValue={uniqueIP}
                                                                                // {row?.value} 
                                                                            onChange={(e) => setUniqueIp(e.target.value)}  />
                                                                        </>) : (<></>)}
                                                                        
                                                                          {projectFilters?.[i]?.slug =="speeder" && showSpeeder ? (<>
                                                                          <span className="required_field">*{speederError}</span>
                                                                            <input type="number" className="small-input" style={{height:"20px", width:"50px"}} placeholder="" id="Speeder" defaultValue={row?.value} onChange={(e) => setSpeeder(e.target.value)}  /> 
                                                                          </>) : (<></>)}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                        <div className="form_divide"><hr ></hr></div>
                                                        <h1 className="project_filter">Security Filter</h1>
                                                        {form?.securityFilters?.map((row, i) => {
                                                            return (
                                                                <div className="col-md-3">
                                                                    <div className="form-check mb-3">
                                                                        <input className="form-check-input" type="checkbox" id={"securityFilter"+row.id} value={row.id} 
                                                                        defaultChecked={row.status === 1 ? true : false}
                                                                        onChange={(e) => handleSecurityFilter(e)} />
                                                                        <label className="form-check-label"htmlFor={"securityFilter"+row.id}>
                                                                            {securityFilters?.[i]?.name}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}

                                                        <div className="form_divide"><hr ></hr></div>
                                                        <h1 className="project_filter">Device Filter <span className="required_field">*{deviceFilterError}</span></h1>
                                                        {form?.deviceFilters?.map((row, i) => {
                                                            return (
                                                                <div className="col-md-3">
                                                                    <div className="form-check mb-3">
                                                                        <input className="form-check-input" type="checkbox" id={"deviceFilter"+row.id} value={row.id}
                                                                        defaultChecked={row.status === 1 ? true : false}
                                                                        onChange={(e) => handleDeviceFilter(e)} />
                                                                        <label className="form-check-label"htmlFor={"deviceFilter"+row.id}>
                                                                            {deviceFilters?.[i]?.name}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}

                                                        <div className="form_divide"><hr ></hr></div>
                                                        <div className="col-md-12">
                                                            <div className="mb-3">
                                                                <label htmlFor="exampleFormControlTextarea5" className="form-label mb-0">Notes </label>
                                                                <textarea className="form-control" id="exampleFormControlTextarea5" defaultValue={form?.notes} rows="3" onChange={(e) => setField('notes',e.target.value)}></textarea>
                                                                <span className="max_char">Maximum limit of characters 250</span>
                                                            </div>
                                                        </div>


                                                        <div className="col-lg-12">
                                                            <div className="text-end">
                                                                <button type="submit" className="btn btn-primary mx-2" onClick={handlesubmit}>Submit</button>
                                                                <button type="submit" className="btn btn-secondary" onClick={()=> navigate('/single-project-detail?id='+id)}>Cancel</button>
                                                            </div>
                                                        </div>


                                                        {/* <!--end col--> */}
                                                    </div>
                                                    {/* <!--end row--> */}
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end col --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Clone_project