import React from 'react'
import { deleteAPI, postAPI, putAPI, get } from "../ApiService";
import Swal from "sweetalert2";
export const ClientList = async({page=0, recPerPage=25, searchKeyword, filters}) => {
    let data = {
        page: page,
        recPerPage: recPerPage,
        q: searchKeyword,
        filters:filters
    }
    let res = await postAPI('/client/list', data)
    return res;
}

export const ClientCreate = async({payload}) => {
    let data = {
        clientName: payload.clientName,
        contactPerson: payload.contactPerson,
        countryID: payload.country,
        email: payload.email,
        contactNumber: payload.contactNumber,
        websiteUrl: payload.websiteUrl
    }
    let res = await postAPI('/client/save', data)
    return res;
}

export const ClientDetail = async({payload}) => {
    let res = await get('/client/details/'+payload)
    return res;
}

export const ClientUpdate = async({payload}) => {
    let data = {
        clientName: payload.clientName,
        contactPerson: payload.contactPerson,
        countryID: payload.Country,
        email: payload.email,
        contactNumber: payload.contactNumber,
        websiteUrl: payload.websiteUrl
    }
    let res = await putAPI('/client/update/'+payload?.clientID, data)
    return res;
}

export const ClientDelete = async({payload}) => {
    return Swal.fire({
        text: 'Are you sure you want to delete?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
      }).then(async (result) => {
        if (result.value) {
            let res = await deleteAPI('/client/delete/'+payload?.clientID)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
      })
}


