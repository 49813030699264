import { deleteAPI, get, postAPI, putAPI } from "../ApiService";
import Swal from "sweetalert2";
export const LibraryQueList = async({page=0, recPerPage=25, searchKeyword, filters, countryID, languageID}) => {
    let data = {
        page: page,
        recPerPage: recPerPage,
        q: searchKeyword,
        countryID: countryID,
        languageID: languageID,
        filters:filters
    }
    let res = await postAPI('/library/question/list', data)
    return res;
}

export const LibraryQueCreate = async({payload}) => {
    let res = await postAPI('/library/question/save', payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const LibraryQueDetail = async({payload}) => {
    let res = await get('/library/question/details/'+payload)
    return res;
}

export const LibraryQueUpdate = async({id, payload}) => {
    let res = await putAPI('/library/question/update/'+id, payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const LibraryQueDelete = async({id}) => {
    return Swal.fire({
        text: 'Are you sure you want to delete?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
      }).then(async (result) => {
        if (result.value) {
            let res = await deleteAPI('/library/question/delete/'+id)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
      })
}

export const validateFormData = (formData) => {
    const{
        // countryID, 
        languageID,
        controlTypeID,
        title,
        question,
        
    } = formData;
    const newErrors = {};
    // if(!countryID || countryID === 'Select Country') newErrors.countryID = 'Please select country';
    if(!languageID || languageID === 'Select Language') newErrors.languageID = 'Please select language';
    if(!controlTypeID || controlTypeID === 'Select Control Type') newErrors.controlTypeID = 'Please select control type';
    if(!title || title === '') newErrors.title = 'Please enter this field';
    if(!question || question === '') newErrors.question = 'Please enter this field';
    return newErrors
}

export const LibraryQueTitleUiqueCheck = async({payload}) => {
    let res = await postAPI('/library/question/check-unique-title/', payload)
    return res
}

