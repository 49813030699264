import React from 'react'
import Footer from '../Layout/Footer'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { defaultPage } from '../../Common/Constant'
import { addPreScreenTemplate, preScreenTemplateDelete, PreScreenTemplateDetail, PreScreenTemplateList } from '../../Api/PreScreen'
import Preloader from '../Layout/Preloader'
import Pagination from '../Layout/Pagination'
import { CCollapse, CButton, CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter } from '@coreui/react'

function Prescreen() {
    const navigate = useNavigate();
    const [filters, setFilters] = useState([])
    const [searchKeyword, setSearchKeyword] = useState();
    const [dataCount, setDataCount] = useState()
    const [lists, setLists] = useState();
    const [pageInfo, setPageInfo] = useState(defaultPage)
    const [templateName, setTemplateName] = useState();
    const [visibleTemplateDetail, setVisibleTemplateDetail] = useState(false)
    const [detail, setDetail] = useState()
    useEffect(() => {
        document.title = "Prescreen - Mindforce"
    },);

    useEffect(() => {
        getList();
    },[filters, searchKeyword])

    const getList = async() =>{
        let res = await PreScreenTemplateList({page:0, searchKeyword:searchKeyword, filters:filters});
        if(res.status === 1){
            setLists(res.payload)
            setDataCount(res.dataCount)
            setPageInfo(res.paginationInfo)
        }else{
            setLists([])
        }
    }

    const changePage = (pageInfo) => {
        setPageInfo(pageInfo)
    }

    const addTemplate = async () => {
        let payload = {
            templateName: templateName
        }
        let res = await addPreScreenTemplate({payload:payload});
        if(res.status === 1){
            getList();
        }
    }

    const templateDetail = async (id) => {
        let payload = {
            templateID:id
        }
        let res = await PreScreenTemplateDetail({payload:payload})
        if(res.status === 1){
            setDetail(res.payload[0])
            setTemplateName(res?.payload?.[0]?.title)
            setVisibleTemplateDetail(true);
        }
    }

    const deleteData = async(id) => {
        let payload = {
            ID:id
        }
        let res = await preScreenTemplateDelete({payload:payload});
        if(res.status === 1){
            getList();
        }
    }

    const searchTemplate = async(e) => {
        let keyword = e.target.value
        setSearchKeyword(keyword)
    }

     

    return (
        <div>
            {/* <Sidebar /> */}
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <div>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a >Library</a></li>
                                                <li className="breadcrumb-item active">Prescreen</li>
                                            </ol>
                                        </div>
                                        <h4 className="mb-sm-0">Prescreen</h4>
                                        </div>      

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                            <div className="row">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">
                                        {/* <!-- end card header --> */}
                                        <div className="card-body">
                                            <div className="report">
                                                <form >
                                                    <div className="row">
                                                        <div className='row justify-content-between mt-3'>
                                                            <div className="col-md-2 col-lg-2">
                                                                <div className="mb-3 d-flex page_size_support">
                                                                    <label htmlFor="ProjectManager" className="form-label mb-0 mt-1 ">Page Size : </label>
                                                                    <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" style={{padding: "5px 5px 5px 10px"}}>
                                                                        <option value={""} disabled selected>1</option>
                                                                        <option>10</option>
                                                                        <option>20</option>
                                                                        <option>30</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 col-lg-6">
                                                                <div className="mb-3 d-flex prelabel">
                                                                    <label htmlFor="ProjectManager" className="form-label mb-0 mt-1">PreScreen Template Name: </label>
                                                                    {/* <div className='d-flex'> */}
                                                                    <input type="text" className="form-control" id="templateName" onChange={(e) => setTemplateName(e.target.value)} />
                                                                    <span className="btn btn-primary mx-2" onClick={()=>addTemplate()}>Add</span>
                                                                    {/* </div> */}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 col-lg-4 ">
                                                                <div className="text-end">
                                                                    <div className='search '>
                                                                        <input type="text" className="form-control" placeholder="Search..." autocomplete="off" id="search-options" onChange={(e)=>searchTemplate(e)} />

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <!--end col--> */}


                                                        {/* <!--end col--> */}
                                                        <div className='table-responsive'>
                                                            { lists ? (<>
                                                                <table className="table table-striped table-hover table_client mt-3">
                                                                <thead>
                                                                    <tr className="bg-primary-1" style={{ color: "white" }}>
                                                                        <th scope="col" >Sr.No.</th>
                                                                        <th scope="col" style={{ textAlign:"left" }}>TemplateName</th>
                                                                        <th scope="col"style={{ textAlign:"left" }} >No. of Questions</th>
                                                                        <th scope="col" style={{ textAlign:"left" }}>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {lists?.length > 0 ? (<>
                                                                    {lists?.map((row, i) => {
                                                                        return (<>
                                                                        <tr>
                                                                            <th scope="row">{i+1}</th>
                                                                            <td style={{ textAlign:"left" }}>{row.title}</td>
                                                                            <td style={{ textAlign:"left" }}> {row?.noOfQuestions}</td>
                                                                            <td style={{ textAlign:"left" }}>
                                                                                <span className="p-1"><i className="ri-eye-fill  pointerC" onClick={() => templateDetail(row.ID)} ></i></span>
                                                                                <span className="p-1"><i className="ri-edit-2-line pointerC"  onClick={() => navigate('/update-prescreen-template?id='+row.ID)} ></i></span>
                                                                                <span className="p-1"><i className="ri-delete-bin-2-line del pointerC" onClick={() => deleteData(row.ID)}></i></span>
                                                                            </td>
                                                                        </tr>
                                                                        </>)
                                                                    })}
                                                                        
                                                                    </>) : (<>
                                                                        <tr>
                                                                            <td colSpan={4}>
                                                                                <center>No data found</center>
                                                                            </td>
                                                                        </tr>
                                                                    </>)}
                                                                    
                                                                </tbody>
                                                            </table>
                                                            </>) : (<>
                                                            <Preloader />
                                                            </>)}
                                                            <Pagination pagination={pageInfo} changePage={changePage} />
                                                        </div>

                                                        {/* <!--end col--> */}
                                                    </div>
                                                    {/* <!--end row--> */}
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                {/* <!-- end col --> */}









                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />

            <CModal className='tempQueModal' size='lg' visible={visibleTemplateDetail} onClose={() => setVisibleTemplateDetail(false)}>
            <CModalHeader className='bg-primary'  style={{paddingBottom:"12px"}}>
                <CModalTitle style={{color:"#fff"}}>Template: {detail?.title}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <div className="row g-3">
                    <div className='table-responsive'>
                        <table className="table table-striped table-hover table_client mt-3">
                            <thead>
                                <tr className="bg-primary" style={{ color: "white" }}>
                                    <th scope="col" style={{ textAlign: "left", maxWidth:"10px" }} >S.No.</th>
                                    <th scope="col" style={{ textAlign: "left" }}>Question</th>
                                </tr>
                            </thead>
                            <tbody>
                                {detail?.questions?.map((q, i) => {
                                    return (<>
                                    <tr>
                                        <td style={{ textAlign: "left" }}>{i+1}</td>
                                        <td style={{ textAlign: "left" }}>{q?.question}</td>
                                    </tr>
                                    </>)
                                })}
                                
                            </tbody>
                        </table>
                    </div>
                </div>
                
            </CModalBody>
            
            </CModal>
        </div>
    )
}

export default Prescreen