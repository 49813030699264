import React from 'react'
import Footer from '../../Layout/Footer'
import Header from '../../Layout/Header'
import Sidebar from '../../Layout/Sidebar'
import Preloader from '../../Layout/Preloader';
import Select from 'react-dropdown-select';
import { useEffect, useState, useRef } from 'react'
import { getClientReport, getStatisticsReport } from '../../../Api/Project'

function Statistics_report() {
    useEffect(() => {
        document.title = "Statistics Report - Mindforce"
    },);
    const ddlClient = useRef();
    const [statisticsReportView, setStatisticsReportView] = useState([]);
    const [startDate, setStartTimestamp] = useState();
    const [endDate, setEndTimestamp] = useState();

    useEffect(() => {
    }, []);

    const setTimestamp = (d, t) => {
        if(t === 'startDate')
        setStartTimestamp((Date.parse(d))/1000);
        else if(t === 'endDate')
        setEndTimestamp((Date.parse(d))/1000);
    }

    const getDownloadReport = async(e) => {
        e.preventDefault();
        let payload = {
            "startDate": startDate,
            "endDate": endDate,
            "viewType": 'download'
        }
        let resClntRpt = await getStatisticsReport({payload});
        if(resClntRpt.status === 1){
            window.open(resClntRpt?.payload?.reportURL);
        } 
    };

    const getViewReport = async(e) => {
        e.preventDefault();
        let payload = {
            "startDate": startDate,
            "endDate": endDate,
            "viewType": 'list'
        }
        let resClntRpt = await getStatisticsReport({payload});
        if(resClntRpt.status === 1){
            setStatisticsReportView(resClntRpt?.payload)
        }
    };

    const getClearSelection = (e) => {
        e.preventDefault();
        setStatisticsReportView([]);
        setStartTimestamp('');
        setEndTimestamp('');
    };

    return (
        <div>
            {/* <Sidebar /> */}
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            <div className="row">
                                <div className="col-12 col-lg-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <div>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a >Report</a></li>
                                                <li className="breadcrumb-item active">Statistics Report</li>
                                            </ol>
                                        </div>
                                        <h4 className="mb-sm-0">Statistics Report</h4>
                                        </div>
                                        

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}

                            <div className="row">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">

                                        {/* <!-- end card header --> */}

                                        <div className="card-body">
                                            <div className="report">
                                                <form >
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="mb-3">
                                                                        <label htmlFor="Projectdescription" className="form-label mb-0">From <span className="required_field">*</span></label>
                                                                        <input type="date" className="form-control" id="startDate" max={new Date().toISOString().split("T")[0]} onChange={() => setTimestamp(document.getElementById('startDate').value, 'startDate')} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="mb-3">
                                                                        <label htmlFor="exampleInputdate" className="form-label mb-0">To <span className="required_field">*</span></label>
                                                                        <input type="date" className="form-control" id="endDate" max={new Date().toISOString().split("T")[0]} onChange={() => setTimestamp(document.getElementById('endDate').value, 'endDate')} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-lg-4">
                                                        <div className="report_btn">
                                                                <button type="submit" className="btn btn-primary" onClick={(e) => getViewReport(e)}>View</button>
                                                                <button type="submit" className="btn btn-primary mx-2" onClick={(e) => getDownloadReport(e)}>Download</button>
                                                                <button type="submit" className="btn btn-primary" onClick={(e) => getClearSelection(e)}>Reset</button>
                                                               
                                                            </div>
                                                        </div>
                                                        {/* <!--end col--> */}
                                                       
                                                     
                                                        {/* <!--end col--> */}
                                                       


                                                       


                                                        {/* <!--end col--> */}
                                                    </div>
                                                    {/* <!--end row--> */}
                                                    <div className='row'>
                                                        {statisticsReportView ? (
                                                                <>
                                                                <div className='hScrollDiv'>
                                                                    <table className="table table-striped table-hover  table_client">
                                                                        {
                                                                            statisticsReportView?.length > 0 ? (
                                                                                <>
                                                                                    <thead>
                                                                                        <tr className="bg-primary" style={{ color: "white" }}>
                                                                                            <th>S.No.</th>
                                                                                            <th style={{ textAlign:"left" }}>Project Code</th>
                                                                                            <th style={{ textAlign:"left" }}>Project Name</th>
                                                                                            <th style={{ textAlign:"left" }}>Client Name</th>
                                                                                            <th>Created On</th>
                                                                                            <th>Clicks</th>
                                                                                            <th>Completes</th>
                                                                                            <th>Category Name</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            statisticsReportView?.map((row, i) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <tr>
                                                                                                            <td scope="row">{i + 1}</td>
                                                                                                            <td style={{ textAlign:"left" }}>{row?.projectID}</td>
                                                                                                            <td style={{ textAlign:"left" }}>{row?.projectName}</td>
                                                                                                            <td style={{ textAlign:"left" }}>{row?.clientName}</td>
                                                                                                            <td>{row?.createdOn}</td>
                                                                                                            <td>{row?.clicks}</td>
                                                                                                            <td>{row?.completes}</td>
                                                                                                            <td>{row?.categoryName}</td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                );
                                                                                            })
                                                                                        }
                                                                                    </tbody>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                   <tr>
                                                                                        <td colSpan={13}>
                                                                                            <center>No statistics data found</center>
                                                                                        </td>
                                                                                    </tr> 
                                                                                </>
                                                                            )
                                                                        }
                                                                    </table>
                                                                </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Preloader />
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                {/* <!-- end col --> */}









                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Statistics_report