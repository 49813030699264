import React from "react";
import { NavLink } from "react-router-dom";

import { useEffect, useState } from "react";
import { ClientDelete, ClientList } from "../../Api/Client";
import { defaultPage } from "../../Common/Constant";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import Pagination from "../Layout/Pagination";
import Preloader from "../Layout/Preloader";
import Sidebar from "../Layout/Sidebar";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const Client =() => {
    const navigate = useNavigate();
    useEffect(() => {
    document.title = "All Client - Mindforce";
  });
  const [filters, setFilters] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState();
    const [lists, setLists] = useState();
    const [dataCount, setDataCount] = useState();
  const [pageInfo, setPageInfo] = useState(defaultPage);
    useEffect(() => {
        getList();
  }, [filters]);

    const handleFilter = (e) => {
    setFilters([]);
    if (e.target.value !== "total") {
      setFilters([
        {
                field: e.target.name,
          value: e.target.value,
        },
      ]);
    }
  };

    const getList = async() =>{
    let res = await ClientList({
      page: 0,
      searchKeyword: searchKeyword,
      filters: filters,
    });
        if(res.status === 1){
      setLists(res.payload);
      setDataCount(res.dataCount);
      setPageInfo(res.paginationInfo);
        }else{
      setLists([]);
    }
  };

    const changePage = (pageInfo) => {
    setPageInfo(pageInfo);
  };

    const deleteRow = async (payload) => {
    let res = await ClientDelete({ payload: payload });
        if(res.status === 1){
      getList();
    }
  };
    return (
        <div>
      {/* <Sidebar /> */}
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            <div className="row">
                                <div className="col-12 col-lg-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <div>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                          <li className="breadcrumb-item">
                            <a> Client</a>
                          </li>
                          <li className="breadcrumb-item active">
                            All Clients
                          </li>
                                            </ol>
                                        </div>
                      <h4 className="mb-sm-0">All Clients</h4>
                    </div>

                    <NavLink
                      to={"/add-client"}
                      className="btn btn-md btn-primary"
                    >
                      Add Client
                    </NavLink>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                            <div className="client_active_btn d-flex ">
                <input
                  type="radio"
                  className="btn-check"
                  name="status"
                  value="total"
                  id="option1"
                  onClick={(e) => handleFilter(e)}
                  autocomplete="off"
                />
                                {/* <label className="btn btn-primary" htmlFor="option1" style={{backgroundColor:filters[0] ? 'white' : '#405189', color:filters[0] ? 'black' : 'white'}}>Total-{dataCount?.total ? dataCount?.total : 0}</label> */}
                <div>
                  {" "}
                  <label
                    className="btn btn-white btn-sm"
                    htmlFor="option1"
                    style={{
                      backgroundColor: filters[0] ? "white" : "#979292",
                      color: filters[0] ? "black" : "white",
                    }}
                  >
                    Total
                  </label>
                  <label htmlFor="option1" className="btn btn-color btn-sm">
                    {dataCount?.total ? dataCount?.total : 0}
                  </label>
                </div>

                <input
                  type="radio"
                  className="btn-check "
                  name="status"
                  value="active"
                  id="option2"
                  onClick={(e) => handleFilter(e)}
                  autocomplete="off"
                />
                                {/* <label className="btn btn-primary mx-2" htmlFor="option2" style={{backgroundColor:filters[0]?.value === 'active' ? '#405189' : 'white', color:filters[0]?.value === 'active' ? 'white' : 'black'}}>
                                    <span className="badge bg-success active_btn">a</span> Active-{dataCount?.active ? dataCount?.active : 0}
                                </label> */}
                <div>
                  {" "}
                  <label
                    className="btn btn-white btn-sm"
                    htmlFor="option2"
                    style={{
                      backgroundColor:
                        filters[0]?.value === "active" ? "#979292" : "white",
                      color: filters[0]?.value === "active" ? "white" : "black",
                    }}
                  >
                    Active{" "}
                  </label>
                  <label htmlFor="option2" className="btn btn-color1 btn-sm">
                    {dataCount?.active ? dataCount?.active : 0}
                  </label>
                </div>

                <input
                  type="radio"
                  className="btn-check"
                  name="status"
                  value="inactive"
                  id="option3"
                  onClick={(e) => handleFilter(e)}
                  autocomplete="off"
                />
                                {/* <label className="btn btn-secondary" htmlFor="option3" style={{backgroundColor:filters[0]?.value === 'inactive' ? '#405189' : 'white', color:filters[0]?.value === 'inactive' ? 'white' : 'black'}}><span className="badge bg-warning inactive_btn">a</span> In Active-{dataCount?.inactive ? dataCount?.inactive : 0}</label> */}
                <div>
                  {" "}
                  <label
                    className="btn btn-white btn-sm"
                    htmlFor="option3"
                    style={{
                      backgroundColor:
                        filters[0]?.value === "inactive" ? "#979292" : "white",
                      color:
                        filters[0]?.value === "inactive" ? "white" : "black",
                    }}
                  >
                    In Active{" "}
                  </label>
                  <label htmlFor="option3" className="btn btn-color2 btn-sm">
                    {dataCount?.inactive ? dataCount?.inactive : 0}
                  </label>
                </div>

                <div className="search" style={{ display: "flex" }}>
                  <input
                    style={{ borderRadius: "5px 0 0 5px" }}
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    autocomplete="off"
                    defaultValue={searchKeyword}
                    onChange={(e) => setSearchKeyword(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") getList();
                    }}
                  />
                  <button style={{borderRadius: "0 5px 5px 0"}} className="btn btn-warning" onClick={() => getList()}>
                    <span className="mdi mdi-magnify search-widget-icon"></span>
                  </button>
                                </div>

                            </div>
                            <div className="row mt-2">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">
                                        {/* <!-- end card header --> */}
                    <div className="card-body" style={{padding: "10px"}}>
                      {lists ? (
                        <>
                                            <table className="table table-striped table-hover table_client">
                                                <thead>
                              <tr
                                className="bg-primary-1"
                                style={{ color: "white" }}
                              >
                                                        <th scope="col">Sr. No.</th>
                                <th scope="col" style={{ textAlign: "left" }}>
                                  Action
                                </th>
                                <th scope="col" style={{ textAlign: "left" }}>
                                  Client Code
                                </th>
                                <th scope="col" style={{ textAlign: "left" }}>
                                  Client Name
                                </th>
                                                        <th scope="col">Country</th>
                                                        <th scope="col">Total Projects</th>
                                                        <th scope="col">Active Projects</th>
                                                        <th scope="col">Click</th>
                                                        <th scope="col">Complete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                              {lists?.length > 0 ? (
                                <>
                                                        {lists?.map((row, i) => {
                                    return (
                                      <>
                                                            <tr>
                                                                <th scope="row">{i+1}</th>
                                                                <td style={{ textAlign:"left" }}> 
                                            <span className="p-1">
                                              <i
                                                className="ri-edit-2-line pointerC"
                                                onClick={() =>
                                                  navigate("/client-edit", {
                                                    state: row?.clientID,
                                                  })
                                                }
                                              ></i>
                                            </span>
                                            <span className="p-1">
                                              <i
                                                className="ri-delete-bin-2-line del pointerC"
                                                onClick={() => deleteRow(row)}
                                              ></i>
                                            </span>
                                          </td>
                                          <td style={{ textAlign: "left" }}>
                                            {row.clientCode}
                                          </td>
                                          <td style={{ textAlign: "left" }}>
                                            {row.clientName}
                                                                </td>
                                                                <td>{row.countryName}</td>
                                          <td>
                                            <span className="badge bg-primary">
                                              {row.totalProjects}
                                            </span>
                                          </td>
                                          <td>
                                            <span className="badge bg-primary">
                                              {row.activeProjects}
                                            </span>
                                          </td>
                                                                <td>{row.clicks}</td>
                                                                <td>{row.completes}</td>
                                                            </tr>
                                      </>
                                    );
                                                    })}
                                </>
                              ) : (
                                <>
                                                        <tr>
                                                            <td colSpan={9}>
                                                                <center>No data found</center>
                                                            </td>
                                                        </tr>
                                </>
                              )}
                                                </tbody>
                                            </table>
                        </>
                      ) : (
                        <>
                                                <Preloader />
                        </>
                      )}
                      <Pagination
                        pagination={pageInfo}
                        changePage={changePage}
                      />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
  );
};

export default Client;
